@import '../../mq.scss';

.modal-card-body {
    color:#000;
}
.modal-card-head {
    padding-top: 15px;
    padding-bottom: 15px;
  
    .modal-card-title {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .modal-card-foot {
    background-color: whitesmoke !important;
    color: #000;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ContactButton {
    margin-right: 20px;
  }