@import '../../mq.scss';

.navbar {
  z-index: 99;
  border-bottom: 2px solid white;
}
.navbar-item {
  font-size: 0.75rem
}
.navbar-end a.navbar-item {
  padding: 0.5rem 1.75rem;
  &:hover {
    color: $primary;
  }
  .icon {
    padding: 0rem 1rem;
  }
}
.navbar-end a.navbar-item.is-active {
  color: $primary;
}