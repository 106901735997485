@import '../../mq.scss';

.List {
  .LeftIcon {
    padding-top: 0;
    padding-bottom: 0;
    @include desktop {
      flex-direction: row;
      display: flex;
    }
    @include tablet {
      flex-direction: row;
      display: flex;
    }
    @include mobile {
      margin: 2rem 0 0 0;
    }
  }
  .icon {
    margin-top: auto;
    margin-bottom: auto;
    background-color: $primary;
    border-radius: 50%;
    color: white;
  }
  .line {
    float: left;
    z-index: -1;
    position: relative;
    left: 50%;
    border-left: 2px solid $primary;
    margin-left: -1px;
    height: 100%;
  }
  .line.first {
    height: 50%;
    top: 50%;
  }
  .line.last {
    height: 50%;
    top: 0;
  }
}
.Portugal img {
  height: 400px;
  width: auto;
  @include mobile {
    padding-top: 2rem;
  }
}
.GreenEnergy {
  padding-top: 2rem;
  background-color: darkolivegreen;

  .LeftIcon {
    color: $primary;
    font-size: 25px;
    margin: auto 1.5rem;
    @include mobile {
      padding: 2rem 0 0 0;
    }
  }
}