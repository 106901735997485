// 2. Set your own initial variables

$darkred: #6e000a;
$darkred-inverted: findColorInvert($darkred);

$darkredFaded: rgba(110, 0, 10, 0.7);


// 3. Set the derived variables
$primary: $darkred;
$primary-invert: $darkred-inverted;

$primary-hover: #550008;

// Responsiveness
// 960, 1152, and 1344 have benn chosen because they are divisible by both 12 and 16
$tablet: 769px !default;
//960px container + 40px
$desktop: 1000px !default;
// 1152px container + 40
$widescreen: 1192px !default;
// 1344px container + 40
$fullhd: 1384px !default;

@mixin mobile {
  @media screen and (max-width: ($tablet - 1px)) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}
@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: ($desktop - 1px)) {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}
@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: ($widescreen - 1px)) {
    @content;
  }
}
@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}
@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: ($fullhd - 1px)) {
    @content;
  }
}// extra classes
.is-vertical-center {
  display: flex;
  align-items: center;
}
.is-vertical-center-mobile {
  @include mobile {
    display: flex;
    align-items: center;
  }
}
.is-vertical-center-tablet {
  @include tablet {
    display: flex;
    align-items: center;
  }
}
.is-vertical-center-desktop {
  @include desktop {
    display: flex;
    align-items: center;
  }
}