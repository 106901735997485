@import '../../mq.scss';

.MainHero {
  position: relative;
}
.MainHero #angles {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: skewY(-3deg);
  transform-origin: 0;
  position: absolute;
  box-shadow: inset 0 -10px 80px -10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(150deg, #99000c 15%, #6e000a 50%, #4c0006 94%);

  .flasks {
    position: relative;
    width: 100%;
    height: 100%;
        //background: url(../../assets/background_pattern1.png)
    background: url(../../assets/background-icons.png);
    background-size: 100px;
    opacity: 0.1;
  }
}
.MainHero .title {
  color: white;
  font-size: 4rem;
  padding: 1.5rem 0;
  @include mobile {
    font-size: 3rem;
  }
}
.MainHero .subtitle {
  color: white;
  font-size: 2rem;
  @include mobile {
    font-size: 1rem;
  }
}
.Logo img {
  border-radius: 50%;
  width: 290px;
  @include mobile {
    margin: 0 auto;
    padding: 0 3rem;
  }
  @include tablet {
    padding: 0 2rem;
  }
}
.Simbols img {
  position: relative;
  left: -40px;
  top: 0px;
  height: 150px;
  width: auto;
}