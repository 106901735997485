@import '../../mq.scss';

.Industry {
  @include widescreen {
    padding: 0 10rem;
  }
}
.card .card-footer {
  background-color: #fff !important;
  color: black;
}
.hoverCard {
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  margin-top: auto;
  margin-bottom: auto;
  &:hover {
    background-color: rgba(110, 0, 10, 0.7);
    opacity: 1;
  }
  p {
    margin-top: 20%;
    color: #fff;
  }
}