@import '../../mq.scss';

footer {
  background-color: $primary !important;
  color: #ffffff;
  padding: 1rem !important;
 
  .icon {
    color: #ffffff;
    margin-left: 20px;
  }
  .Copyright {
    padding: 1rem 0 0 0;
    color: white;
  }
  .Address {
    margin-bottom: 0 !important;
  }
  .LeftCol p {
    margin-bottom: 5px;
  }
  .content h2 {
    color: white;
  }
  .livro_rec {
    width: 80px;
  }

}
  .footer a {
    color: white;
  
}
  .footer a:hover {
    color: rgb(216, 202, 202);
}