@import '../../mq.scss';

.ProductsTable tbody {
  height: 70%;
  overflow: scroll;

  tr {
    cursor: pointer;
  }
  th {
    white-space: nowrap;
  }
  tr.is-selected {
    background-color: $primary;
    &:hover {
      background-color: $primary-hover;
    }
  }
}